import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Stages from '../../components/Stages'

const StagesPrzetargi = ({ data }) => {
  const query = useStaticQuery(graphql`
    {
      image1: file(
        absolutePath: { regex: "/images/przetargi/1.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 700
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [WEBP]
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/przetargi/2.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 700
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [WEBP]
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/przetargi/3.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 700
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [WEBP]
          )
        }
      }
      image4: file(
        absolutePath: { regex: "/images/przetargi/4.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 700
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [WEBP]
          )
        }
      }
    }
  `)

  return <Stages query={query} data={data} />
}

export default StagesPrzetargi
