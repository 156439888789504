export const seo = {
  url: 'przetargi',
  title: {
    pl: `Przetargi w branży logistycznej`,
    en: ``,
  },
  desc: {
    pl: 'Doświadczona firma logistyczna, gotowa na wyzwania przetargowe! Odkryj, jak możemy optymalizować Twoje procesy logistyczne i zapewnić niezawodną obsługę. Poznaj Omida VLS - Twojego partnera w przetargach logistycznych.',
    en: '',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  titleHtml: {
    pl: `Przetargi w branży <br/>logistycznej`,
    en: 'Collaboration',
  },
  desc: {
    pl: `<strong>Zyskaj przewagę z Omida VLS</strong> <br/>Doświadczona firma logistyczna, gotowa na wyzwania przetargowe! Odkryj, jak możemy optymalizować Twoje procesy logistyczne i&nbsp;zapewnić niezawodną obsługę. Poznaj Omida VLS - Twojego partnera w&nbsp;przetargach logistycznych.`,
    en: `Start cooperation in the field of transport and logistics with an experienced TSL company. Specialized services, dedicated transport and customization.`,
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Kluczowy proces wyboru dostawców usług',
        en: '',
      },
      texts: [
        {
          pl: `Przetargi w branży logistycznej stanowią istotny element procesu wyboru dostawców usług logistycznych przez firmy działające w różnych sektorach. Są to formalne procedury, które pozwalają firmom na wybór najlepszego dostawcy, zgodnie z określonymi kryteriami i wymaganiami. W ramach przetargów logistycznych, firmy mogą zlecać realizację zadań związanych z transportem, magazynowaniem, spedycją oraz innymi aspektami logistyki.`,
          en: ``,
        },
      ],
    },
  ],
}

export const collabForm = {
  title: {
    pl: `Pracuj z Omida VLS`,
    en: '',
  },
  desc: {
    pl: `Jeśli jesteś zainteresowany współpracą z Omida VLS w ramach przetargów logistycznych, zapraszamy do kontaktu. W celu omówienia szczegółów przetargu i dostosowania naszej oferty do Twoich potrzeb, prosimy o wypełnienie formularza kontaktowego lub bezpośredni kontakt mailowy lub telefoniczny.`,
    en: '',
  },
}

export const testimonials = {
  title: {
    pl: `Poznaj opinie naszych klientów`,
    en: '',
  },
  desc: {
    pl: ``,
    en: '',
  },
}

export const stages = {
  id: 'etapy',
  title: {
    pl: 'Współpraca z Omida VLS w ramach przetargów',
    en: 'Processes - Intermodal Transport',
  },
  desc: {
    pl: 'Omida VLS to doświadczony partner w zakresie usług logistycznych, który posiada szeroką gamę rozwiązań dostosowanych do potrzeb różnych branż i sektorów. Dlaczego warto zaprosić Omida VLS do udziału w przetargu logistycznym? Oto kilka kluczowych powodów:',
    en: 'Learn about the Omida Intermodal service processes',
  },
  stage: {
    pl: '',
    en: '',
  },
  stages: [
    {
      number: '01',
      title: {
        pl: 'Profesjonalizm i doświadczenie',
        en: 'Reception',
      },
      body: {
        pl: `Omida VLS to zespół specjalistów z bogatym doświadczeniem w branży logistycznej. Dzięki naszemu profesjonalizmowi i zaangażowaniu jesteśmy  w stanie zapewnić wysoką jakość obsługi oraz terminową realizację zleceń.`,
        en: `<ul><li>At this stage, we collect our client's goods and transport them by tractor and semi-trailer to the railway terminal.</li><li> We offer goods collection as part of intermodal transport within a radius of 300 km from the railway terminals.</li></ul>`,
      },
    },
    {
      number: '02',
      title: {
        pl: `Wszechstronne usługi logistyczne`,
        en: `Delivery to the Terminal`,
      },
      body: {
        pl: 'Nasza firma oferuje kompleksowe usługi transportowe, magazynowe oraz spedycyjne, co pozwala na elastyczne dostosowanie się do różnorodnych potrzeb klientów. Bez względu na rodzaj przetargu i specyfikę zlecenia, jesteśmy w stanie zapewnić optymalne rozwiązania logistyczne.',
        en: '<ul><li>After our driver takes over the goods, the loaded trailer is delivered to the terminal.</li><li> Drivers driving in wheels on the routes served, in an intermodal product, obtain the best delivery times.</li> </ul>',
      },
    },
    {
      number: '03',
      title: {
        pl: 'Innowacyjne podejście',
        en: 'Lashing the Cargo',
      },
      body: {
        pl: `W Omida VLS stale poszukujemy nowych rozwiązań i technologii, które mogą usprawnić nasze procesy i zwiększyć efektywność działania. Dzięki temu jesteśmy  w stanie sprostać nawet najbardziej wymagającym przetargom i zapewnić naszym klientom konkurencyjną przewagę.`,
        en: `
        <ul>
          <li>After reaching the place of loading, the trailer is transferred to a dedicated wagon.</li>
          <li>Reach stackers and cranes are used for this purpose.</li>
          <li>When the semi-trailer is loaded onto the rolling stock, the tractor driver can make subsequent deliveries and pick-ups within a radius of 300 km from the railway terminal.</li>
      </ul>
        `,
      },
    },
    {
      number: '04',
      title: {
        pl: 'Zaangażowanie w realizację celów klienta',
        en: 'Carriage',
      },
      body: {
        pl: `Naszym priorytetem jest satysfakcja klienta oraz osiągnięcie jego celów biznesowych. Dlatego też angażujemy się w każdy projekt z pełnym zaangażowaniem  i profesjonalizmem, dbając o to, aby każde zlecenie zostało zrealizowane zgodnie  z oczekiwaniami klienta.`,
        en: `
        <ul>
        <li>At a certain time, almost every day, the entire train depot starts from the terminal to transport the goods to their final destination.</li>
        <li>The key advantage in rail transport is the continuous movement of our goods.</li>
      </ul>
        `,
      },
    },
  ],
}
