import React from 'react'
import Section from '../../components/Section'
import Headline from '../../components/Headline'
import { SectionBackground } from '../../components/Section'
import FormDynamic from '../../components/FormDynamic'

const FormCollabSection = ({title, desc}) => {

  return (
    <>
      <Section top mobile bottomXS>
        <Headline
          h={2}
          title={title}
          desc={desc}
          large
          gradient
        />
      </Section>
      <SectionBackground mobile bottomXS>
        <FormDynamic notypes override='COLLAB' />
      </SectionBackground>
    </>
  )
}

export default FormCollabSection